var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "tab" },
          _vm._l(_vm.tabList, function (item, $index) {
            return _c(
              "span",
              {
                directives: [
                  {
                    name: "permissson",
                    rawName: "v-permissson",
                    value: `${item.permissson}`,
                    expression: "`${item.permissson}`",
                  },
                ],
                key: item.permissson,
                class: ["tabItem", _vm.active === $index ? "active" : ""],
                on: {
                  click: function ($event) {
                    return _vm.changeType($index)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(item.name) +
                    " (" +
                    _vm._s(
                      item.type === 10000
                        ? _vm.tabTotal
                        : _vm.tabNumer.find((a) => a.type === item.type)?.qty ||
                            0
                    ) +
                    ") "
                ),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "opt" },
          [
            _vm.filterList.date && _vm.isActive
              ? _c("div", { staticClass: "checkDate flex_c_c" }, [
                  _vm._v(
                    " 查询时间：" +
                      _vm._s(
                        _vm.filterList.date[0] + "~" + _vm.filterList.date[1]
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm._l(_vm.importList, function (item, $index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === $index,
                      expression: "active === $index",
                    },
                    {
                      name: "permissson",
                      rawName: "v-permissson",
                      value: `${item}`,
                      expression: "`${item}`",
                    },
                  ],
                  key: item,
                  staticClass: "upload flex_c_c",
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible2 = true
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/order/upload.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" 导入 "),
                ]
              )
            }),
            _c(
              "div",
              { staticClass: "download flex_c_c", on: { click: _vm.download } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/order/download.png"),
                    alt: "",
                  },
                }),
                _vm._v(" 下载模板 "),
              ]
            ),
            _vm._l(_vm.contrastList, function (item, $index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === $index,
                      expression: "active === $index",
                    },
                    {
                      name: "permissson",
                      rawName: "v-permissson",
                      value: `${item}`,
                      expression: "`${item}`",
                    },
                  ],
                  key: `${item}`,
                  staticClass: "contrast flex_c_c",
                  on: { click: _vm.showChEnContrast },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/const/contrast.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" 费用中英文对照表 "),
                ]
              )
            }),
            _c(
              "BaseFilter",
              {
                attrs: { isActive: _vm.isActive, width: 504 },
                on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
              },
              [
                _c(
                  "div",
                  { staticClass: "filter" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.filterList,
                          "label-width": "120px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "对账日期" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "320px" },
                              attrs: {
                                type: "daterange",
                                "range-separator": "至",
                                align: "center",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "value-format": "yyyy-MM-dd",
                                "unlink-panels": "",
                                "picker-options": _vm.pickerOptions,
                              },
                              model: {
                                value: _vm.filterList.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "date", $$v)
                                },
                                expression: "filterList.date",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择客户",
                                },
                                model: {
                                  value: _vm.filterList.companyId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterList, "companyId", $$v)
                                  },
                                  expression: "filterList.companyId",
                                },
                              },
                              _vm._l(_vm.companyOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { "line-height": "20px" },
                                attrs: { slot: "label" },
                                slot: "label",
                              },
                              [
                                _vm._v(" 账单号码/主单号"),
                                _c("br"),
                                _vm._v("/参考单号 "),
                              ]
                            ),
                            _c("base-muti-input", {
                              ref: "baseMutiInput",
                              staticStyle: { width: "320px" },
                              model: {
                                value: _vm.filterList.numbers,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "numbers", $$v)
                                },
                                expression: "filterList.numbers",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                key: _vm.active,
                attrs: {
                  height: _vm.height,
                  tableData: {
                    ..._vm.tableData,
                    fileds: _vm.fileds,
                  },
                  pageIndex: _vm.pager.pageIndex,
                  pageSize: _vm.pager.pageSize,
                  loading: _vm.loading,
                },
                on: {
                  handlePageChange: _vm.handlePageChange,
                  handleSelectionChange: _vm.handleSelectionChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "table-opt" }, [
                            _vm.active === 2
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "reconciliation",
                                        expression: "'reconciliation'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.reconciliation(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("对账")]
                                )
                              : _vm._e(),
                            _vm.active === 2 || _vm.active === 3
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value:
                                          _vm.active === 2
                                            ? "derive_b"
                                            : "derive_c",
                                        expression:
                                          "active === 2? 'derive_b' : 'derive_c'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.derive(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("导出")]
                                )
                              : _vm._e(),
                            _vm.active === 1
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "BillingDetail_a",
                                        expression: "'BillingDetail_a'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.showBillingDetail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("账单明细")]
                                )
                              : _vm._e(),
                            _vm.active === 2
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "BillingDetail_b",
                                        expression: "'BillingDetail_b'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.showBillingDetail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("账单明细")]
                                )
                              : _vm._e(),
                            _vm.active === 3
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "BillingDetail_c",
                                        expression: "'BillingDetail_c'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.showBillingDetail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("账单明细")]
                                )
                              : _vm._e(),
                            _vm.active === 4
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "BillingDetail_d",
                                        expression: "'BillingDetail_d'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.showBillingDetail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("账单明细")]
                                )
                              : _vm._e(),
                            _vm.active === 1
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "check",
                                        expression: "'check'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.showCheck(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("核对")]
                                )
                              : _vm._e(),
                            _vm.active === 1
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "delete_a",
                                        expression: "'delete_a'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                            _vm.active === 2
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "delete_b",
                                        expression: "'delete_b'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "custom-total",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "custom-total" }, [
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c("div", { staticClass: "select" }, [
                                _vm._v(
                                  "已选 " +
                                    _vm._s(_vm.selectionItem.length) +
                                    " 项"
                                ),
                              ]),
                              _c("div", { staticClass: "total" }, [
                                _vm._v(" 账单总金额："),
                                _c("span", [_vm._v(_vm._s(_vm.totalAmount))]),
                              ]),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1145461626
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("FeeChEnContrastDialog", {
        attrs: { dialogVisible: _vm.dialogVisible1 },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible1 = false
          },
        },
      }),
      _c("ImportFileDialog", {
        attrs: { dialogVisible: _vm.dialogVisible2 },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible2 = false
          },
          ok: _vm.ok,
        },
      }),
      _c("BillingDetailDialog", {
        attrs: { dialogVisible: _vm.dialogVisible3, rowData: _vm.rowData },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible3 = false
          },
        },
      }),
      _c("CheckBillingDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible4,
          rowData: _vm.rowData,
          companyOptions: _vm.companyOptions,
        },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible4 = false
          },
          ok: _vm.ok,
        },
      }),
      _c("DeriveDialog", {
        attrs: { dialogVisible: _vm.dialogVisible5, rowData: _vm.rowData },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible5 = false
          },
        },
      }),
      _c("ReconciliationDialog", {
        attrs: { dialogFormVisible: _vm.dialogVisible6, rowData: _vm.rowData },
        on: {
          ok: _vm.ok,
          cancel: function ($event) {
            _vm.dialogVisible6 = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }