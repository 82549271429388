var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "导入",
        visible: _vm.dialogVisible,
        width: "520px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.beforeClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "el-row",
                { staticStyle: { width: "100%" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("label", { staticClass: "lable" }, [
                          _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                          _vm._v("账单类型"),
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "type" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "190px" },
                                attrs: { placeholder: "请选择账单类型" },
                                model: {
                                  value: _vm.form.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "type", $$v)
                                  },
                                  expression: "form.type",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "UPS", value: 0 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "FedEx", value: 1 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("label", { staticClass: "lable" }, [
                          _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                          _vm._v("币制"),
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "currency" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "190px" },
                                attrs: { placeholder: "请选择币制" },
                                model: {
                                  value: _vm.form.currency,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "currency", $$v)
                                  },
                                  expression: "form.currency",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "USD", value: "USD" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "RMB", value: "RMB" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "upload" },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    drag: "",
                    action: "#",
                    "list-type": "picture",
                    "http-request": _vm.httpRequest,
                    "show-file-list": false,
                  },
                },
                [
                  _c("svg-icon", {
                    attrs: { iconClass: "upload", className: "icon" },
                  }),
                  _c("p", { staticClass: "p1" }, [
                    _vm._v("拖拽你的文件到此处"),
                  ]),
                  _c("p", { staticClass: "p2" }, [_vm._v("或")]),
                  _c("div", { staticClass: "btn" }, [_vm._v("点击上传")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.fileList[0],
                  expression: "fileList[0]",
                },
              ],
              staticClass: "fileLists",
            },
            [
              _c(
                "div",
                {
                  staticClass: "item-upload-area",
                  style: {
                    visibility:
                      _vm.fileList[0] && _vm.fileList[0].fileName
                        ? "visible"
                        : "hidden",
                  },
                },
                [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("img", {
                      attrs: { src: require("@/assets/images/const/xlsx.png") },
                    }),
                    _c("div", { staticClass: "des" }, [
                      _c("p", { staticClass: "fileName" }, [
                        _vm._v(
                          _vm._s(_vm.fileList[0] && _vm.fileList[0].fileName)
                        ),
                      ]),
                      _c("p", { staticClass: "fileSize" }, [
                        _vm._v(
                          _vm._s(_vm.fileList[0] && _vm.fileList[0].fileSize) +
                            " KB"
                        ),
                      ]),
                    ]),
                  ]),
                  _c("i", { staticClass: "el-icon-circle-check" }),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            {
              staticClass: "cancel",
              on: {
                click: function ($event) {
                  return _vm.cancel("form")
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }