<template>
  <div class="view-contanier">
    <div class="header">
      <div class="tab">
        <span
          v-for="(item, $index) in tabList"
          :key="item.permissson"
          v-permissson="`${item.permissson}`"
          :class="['tabItem', active === $index ? 'active' : '']"
          @click="changeType($index)"
        >
          {{ item.name }}
          ({{ item.type === 10000 ? tabTotal : tabNumer.find(a => a.type === item.type)?.qty || 0 }})
        </span>
      </div>
      <div class="opt">
        <div v-if="filterList.date && isActive" class="checkDate flex_c_c">
          查询时间：{{ filterList.date[0] + "~" + filterList.date[1] }}
        </div>
        <div
          v-for="(item, $index) in importList"
          v-show="active === $index"
          :key="item"
          v-permissson="`${item}`"
          class="upload flex_c_c"
          @click="dialogVisible2 = true"
        >
          <img src="@/assets/images/order/upload.png" alt="" />
          导入
        </div>
        <div class="download flex_c_c" @click="download">
          <img src="@/assets/images/order/download.png" alt="" />
          下载模板
        </div>
        <div
          v-for="(item, $index) in contrastList"
          v-show="active === $index"
          :key="`${item}`"
          v-permissson="`${item}`"
          class="contrast flex_c_c"
          @click="showChEnContrast"
        >
          <img src="@/assets/images/const/contrast.png" alt="" />
          费用中英文对照表
        </div>
        <BaseFilter
          :isActive="isActive"
          :width="504"
          @sendFilter="sendFilter"
          @reset="reset"
        >
          <div class="filter">
            <el-form ref="form" :model="filterList" label-width="120px">
              <el-form-item label="对账日期">
                <el-date-picker
                  v-model="filterList.date"
                  style="width: 320px"
                  type="daterange"
                  range-separator="至"
                  align="center"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  unlink-panels
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="客户">
                <el-select
                  v-model="filterList.companyId"
                  style="width: 320px"
                  filterable
                  placeholder="请选择客户"
                >
                  <el-option
                    v-for="item in companyOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <div slot="label" style="line-height: 20px">
                  账单号码/主单号<br />/参考单号
                </div>
                <base-muti-input
                  ref="baseMutiInput"
                  v-model="filterList.numbers"
                  style="width: 320px"
                ></base-muti-input>
              </el-form-item>
            </el-form>
          </div>
        </BaseFilter>
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :key="active"
        :height="height"
        :tableData="{
          ...tableData,
          fileds,
        }"
        :pageIndex="pager.pageIndex"
        :pageSize="pager.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <span
              v-if="active === 2"
              v-permissson="'reconciliation'"
              @click="reconciliation(row.scope)"
            >对账</span
            >
            <span
              v-if="active === 2 || active === 3"
              v-permissson="active === 2? 'derive_b' : 'derive_c'"
              @click="derive(row.scope)"
            >导出</span
            >
            <span
              v-if="active === 1"
              v-permissson="'BillingDetail_a'"
              @click="showBillingDetail(row.scope)"
            >账单明细</span
            >
            <span
              v-if="active === 2"
              v-permissson="'BillingDetail_b'"
              @click="showBillingDetail(row.scope)"
            >账单明细</span
            >
            <span
              v-if="active === 3"
              v-permissson="'BillingDetail_c'"
              @click="showBillingDetail(row.scope)"
            >账单明细</span
            >
            <span
              v-if="active === 4"
              v-permissson="'BillingDetail_d'"
              @click="showBillingDetail(row.scope)"
            >账单明细</span
            >
            <span
              v-if="active === 1"
              v-permissson="'check'"
              @click="showCheck(row.scope)"
            >核对</span
            >
            <span
              v-if="active === 1"
              v-permissson="'delete_a'"
              @click="deleteRow(row.scope)"
            >删除</span
            >
            <span
              v-if="active === 2"
              v-permissson="'delete_b'"
              @click="deleteRow(row.scope)"
            >删除</span
            >
          </div>
        </template>
        <template #custom-total>
          <div class="custom-total">
            <div style="display: flex;">
              <div class="select">已选 {{ selectionItem.length }} 项</div>
              <div class="total">
                账单总金额：<span>{{ totalAmount }}</span>
              </div>
            </div>
          </div>
        </template>
      </BaseTable>
    </div>
    <FeeChEnContrastDialog
      :dialogVisible="dialogVisible1"
      @cancel="dialogVisible1 = false"
    ></FeeChEnContrastDialog>
    <ImportFileDialog
      :dialogVisible="dialogVisible2"
      @cancel="dialogVisible2 = false"
      @ok="ok"
    ></ImportFileDialog>
    <BillingDetailDialog
      :dialogVisible="dialogVisible3"
      :rowData="rowData"
      @cancel="dialogVisible3 = false"
    ></BillingDetailDialog>
    <CheckBillingDialog
      :dialogVisible="dialogVisible4"
      :rowData="rowData"
      :companyOptions="companyOptions"
      @cancel="dialogVisible4 = false"
      @ok="ok"
    ></CheckBillingDialog>
    <DeriveDialog
      :dialogVisible="dialogVisible5"
      :rowData="rowData"
      @cancel="dialogVisible5 = false"
    ></DeriveDialog>
    <ReconciliationDialog
      :dialogFormVisible="dialogVisible6"
      :rowData="rowData"
      @ok="ok"
      @cancel="dialogVisible6 = false">
    </ReconciliationDialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseFilter = () => import('@/components/filter/BaseFilter')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput.vue')
import { permissions } from '@/utils/common'
import { getReconInvocePages, deleteReconInvoce } from '@/api/fee'
import FeeChEnContrastDialog from './components/FeeChEnContrastDialog.vue'
import ImportFileDialog from './components/ImportFileDialog.vue'
import BillingDetailDialog from './components/BillingDetailDialog.vue'
import CheckBillingDialog from './components/CheckBillingDialog.vue'
import DeriveDialog from './components/DeriveDialog.vue'
import ReconciliationDialog from './components/ReconciliationDialog.vue'
import config from '@/utils/config'
import { mapGetters } from 'vuex'
import { myMixins } from '@/mixins'
export default {
  name: 'DispatchReconciliation',
  components: {
    BaseTable,
    BaseFilter,
    BaseMutiInput,
    FeeChEnContrastDialog,
    ImportFileDialog,
    BillingDetailDialog,
    CheckBillingDialog,
    DeriveDialog,
    ReconciliationDialog
  },
  mixins: [myMixins],
  data() {
    return {
      active: 10000,
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      tableData: {
        fileds: [],
        columns: [],
        total: 0,
        mutiCheck: true
      },
      loading: false,
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      height: 0,
      filterList: {
        date: '',
        companyId: '',
        numbers: ''
      },
      isActive: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      selectionItem: [],
      tabNumer: [],
      tabTotal: 0,
      tabList: [
        { name: '全部', permissson: 'QB', type: 10000 },
        { name: '未核对', permissson: 'WHD', type: 0 },
        { name: '已核对', permissson: 'YHD', type: 1 },
        { name: '已对账', permissson: 'YDZ', type: 2 },
        { name: '已申付', permissson: 'YSF', type: 3 }
      ],
      rowData: {},
      importList: ['import_a', 'import_b', 'import_c', 'import_d'], // 导入按钮的权限验证
      contrastList: ['contrast_a', 'contrast_b', 'contrast_c', 'contrast_d'] // 对照表按钮的权限验证
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown']),
    fileds() {
      const obj = {
        0: [
          {
            filed: 'supplierName',
            label: '供应商'
          },
          {
            filed: 'accountNumber',
            label: '制单账号',
            width: '150'
          },
          {
            filed: 'systemCost',
            label: '系统成本'
          },
          {
            filed: 'accountUsageRate',
            label: '账号使用费率'
          },
          {
            filed: 'invoiceDate',
            label: '账单时间',
            formatter: (row) =>
              row.invoiceDate ? row.invoiceDate.slice(0, row.invoiceDate.length - 9) : ''
          },
          {
            filed: 'invoiceNumber',
            label: '账单号码',
            isCopy: true,
            width: '170'
          },
          {
            filed: 'invoiceAmount',
            label: '账单总金额'
          },
          {
            filed: 'createdName',
            label: '上传人'
          },
          {
            filed: 'createdOn',
            label: '上传时间',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : ''
          }
        ],
        1: [
          {
            filed: 'supplierName',
            label: '供应商'
          },
          {
            filed: 'accountNumber',
            label: '制单账号',
            width: '150'
          },
          {
            filed: 'systemCost',
            label: '系统成本'
          },
          {
            filed: 'accountUsageRate',
            label: '账号使用费率'
          },
          {
            filed: 'invoiceDate',
            label: '账单时间',
            formatter: (row) =>
              row.invoiceDate ? row.invoiceDate.slice(0, row.invoiceDate.length - 9) : ''
          },
          {
            filed: 'invoiceNumber',
            label: '账单号码',
            isCopy: true,
            width: '170'
          },
          {
            filed: 'invoiceAmount',
            label: '账单总金额'
          },
          {
            filed: 'createdName',
            label: '上传人'
          },
          {
            filed: 'createdOn',
            label: '上传时间',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : ''
          },
          {
            filed: 'opt',
            label: '操作',
            width: '220px',
            isCustom: true
          }
        ],
        2: [
          {
            filed: 'supplierName',
            label: '供应商',
            width: '220'
          },
          {
            filed: 'accountNumber',
            label: '制单账号',
            width: '150'
          },
          {
            filed: 'systemCost',
            label: '系统成本'
          },
          {
            filed: 'accountUsageRate',
            label: '账号使用费率',
            width: '120'
          },
          {
            filed: 'invoiceDate',
            label: '账单时间',
            formatter: (row) =>
              row.invoiceDate ? row.invoiceDate.slice(0, row.invoiceDate.length - 9) : '',
            width: '150'
          },
          {
            filed: 'invoiceNumber',
            label: '账单号码',
            isCopy: true,
            width: '170'
          },
          {
            filed: 'invoiceAmount',
            label: '账单总金额',
            width: '180'
          },
          {
            filed: 'createdName',
            label: '上传人',
            width: '140'
          },
          {
            filed: 'createdOn',
            label: '上传时间',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : '',
            width: '150'
          },
          {
            filed: 'checkedName',
            label: '核对人'
          },
          {
            filed: 'checkedDate',
            label: '核对时间',
            formatter: (row) =>
              row.checkedDate ? row.checkedDate.slice(0, row.checkedDate.length - 3) : '',
            width: '150'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '220px',
            isCustom: true,
            fixed: 'right'
          }
        ],
        3: [
          {
            filed: 'supplierName',
            label: '供应商',
            width: '220'
          },
          {
            filed: 'accountNumber',
            label: '制单账号',
            width: '150'
          },
          {
            filed: 'systemCost',
            label: '系统成本',
            width: '120'
          },
          {
            filed: 'accountUsageRate',
            label: '账号使用费率',
            width: '120'
          },
          {
            filed: 'invoiceDate',
            label: '账单时间',
            formatter: (row) =>
              row.invoiceDate ? row.invoiceDate.slice(0, row.invoiceDate.length - 9) : '',
            width: '150'
          },
          {
            filed: 'invoiceNumber',
            label: '账单号码',
            isCopy: true,
            width: '170'
          },
          {
            filed: 'invoiceAmount',
            label: '账单总金额',
            width: '180'
          },
          {
            filed: 'createdName',
            label: '上传人',
            width: '140'
          },
          {
            filed: 'createdOn',
            label: '上传时间',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : '',
            width: '150'
          },
          {
            filed: 'checkedName',
            label: '核对人',
            width: '140'
          },
          {
            filed: 'checkedDate',
            label: '核对时间',
            formatter: (row) =>
              row.checkedDate ? row.checkedDate.slice(0, row.checkedDate.length - 3) : '',
            width: '150'
          },
          {
            filed: 'reconName',
            label: '对账人',
            width: '140'
          },
          {
            filed: 'reconDate',
            label: '对账时间',
            formatter: (row) =>
              row.reconDate ? row.reconDate.slice(0, row.reconDate.length - 3) : '',
            width: '150'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '170',
            isCustom: true,
            fixed: 'right'
          }
        ]
      }
      return this.active !== 4 ? obj[this.active] : obj[3]
    },
    companyOptions() {
      return this.companyDrapdown
    },
    totalAmount() {
      let num = 0
      this.selectionItem.forEach(a => {
        num += +a.invoiceAmount
      })
      return num
    }
  },
  created() {
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 93
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeLits = ['WHD', 'YHD', 'YDZ', 'YSF']
      const childrenCode = menu.children.map(a => a.code)
      this.active = codeLits.findIndex((a) => childrenCode.includes(a))
      this.getLists(this.pager)
    }
  },
  methods: {
    changeType(type) {
      this.active = type
      const param = {
        pageIndex: 1,
        pageSize: this.pager.pageSize
      }
      this.pager = param
      this.selectionItem = []
      this.getLists(param)
    },
    handlePageChange(page) {
      this.pager = page
      this.getLists(page)
    },
    async getLists(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.formatterFilter()
          }
        }
        const res = await getReconInvocePages(params)
        if (res.success) {
          this.loading = false
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
          this.tabNumer = res.data.tabNumer
          this.tabTotal = res.data.tabTotal
        } else {
          this.loading = false
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    formatterFilter() {
      const statusArr = [undefined, 0, 1, 2, 3]
      return {
        status: statusArr.find((a, i) => i === this.active),
        companyId:
          this.filterList.companyId !== ''
            ? this.filterList.companyId
            : undefined,
        numbers:
          this.filterList.numbers.length > 0
            ? this.filterList.numbers
            : undefined,
        beginDate:
          this.filterList.date !== '' ? this.filterList.date[0] : undefined,
        endDate:
          this.filterList.date !== '' ? this.filterList.date[1] : undefined
      }
    },
    showChEnContrast() {
      this.dialogVisible1 = true
    },
    sendFilter() {
      if (
        this.filterList.companyId !== '' ||
        this.filterList.numbers.length !== 0 ||
        this.filterList.date !== ''
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = params
      this.getLists(params)
    },
    reset() {
      this.filterList = {
        date: '',
        companyId: '',
        numbers: ''
      }
      this.$refs.baseMutiInput.reset()
      this.isActive = false
    },
    async download() {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = `/backEnd/api/${config.supplyCode}/file/downLoadTemplate?guid=bde6809ff8814f12963aeb430b49b5f2`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    },
    initList() {
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = param
      this.reset()
      this.getLists(param)
    },
    ok() {
      this.dialogVisible2 = false
      this.dialogVisible4 = false
      this.dialogVisible6 = false
      this.initList()
    },
    // 删除
    async deleteRow(row) {
      try {
        const result = await deleteReconInvoce({ id: row.id })
        if (result.success) {
          this.getLists(this.pager)
          this.$message.success(result.errorMessage)
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    // 账单明细
    async showBillingDetail(row) {
      this.rowData = row
      this.dialogVisible3 = true
    },
    // 核对
    showCheck(row) {
      this.rowData = row
      this.dialogVisible4 = true
    },
    // 对账
    async reconciliation(row) {
      this.rowData = row
      this.dialogVisible6 = true
      // try {
      //   const result = await reconciliation({ id: row.id })
      //   if (result.success) {
      //     this.getLists(this.pager)
      //     this.$message.success(result.errorMessage)
      //   } else {
      //     this.$message.error(result.errorMessage)
      //   }
      // } catch (error) {
      //   console.error(error)
      // }
    },
    // 导出
    derive(row) {
      this.rowData = row
      this.dialogVisible5 = true
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tabItem {
      padding: 15px 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      cursor: pointer;
      box-sizing: border-box;
    }
    .active {
      color: #2395f3;
    }
  }
  .opt {
    display: flex;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    & div {
      height: 30px;
      box-sizing: border-box;
      margin-right: 5px;
      border-radius: 4px;
      cursor: pointer;
    }
    .upload {
      width: 80px;
      background: #35ba83;
      color: #ffffff;
    }
    .download {
      width: 120px;
      border: 1px solid #35ba83;
      color: #35ba83;
    }
    .contrast {
      width: 160px;
      border: 1px solid #eeeeee;
      color: #666666;
    }
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
    .custom-total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      background: #f7f7f7;
      border: 1px solid #eeeeee;
      border-top: 0;
      padding-left: 17px;
      padding-right: 40px;
      box-sizing: border-box;
      .select {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .total {
        margin-left: 16px;
        font-size: 14px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        color: #333333;
        span {
          color: #fe453d;
          font-weight: bold;
        }
      }
    }
  }
  .table-opt {
    display: flex;
    justify-content: space-around;
    span {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      cursor: pointer;
    }
  }
}
</style>
