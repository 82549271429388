<template>
  <el-dialog
    v-drag
    title="账单明细"
    :visible.sync="dialogVisible"
    width="1200px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="header">
        <el-form ref="form" class="form" :model="filterList" label-width="58px">
          <div class="search">
            <el-popover
              ref="popover"
              placement="top"
              class="searchPop"
              title="请输入主单号/参考单号搜索"
              trigger="click"
              width="300"
            >
              <div class="close" @click="closeSearchPanel">
                <i class="el-icon-close"></i>
              </div>
              <base-muti-input
                ref="baseMutiInput"
                v-model="filterList.numbers"
              ></base-muti-input>
              <div class="popover_footer flex_c_c">
                <div class="clear flex_c_c" @click="handleEmpty">清空</div>
                <div class="confirm flex_c_c" @click="sendFilter('popover')">确定</div>
              </div>
            </el-popover>
            <el-input
              :value="searchVal"
              placeholder="请输入提单号搜索"
              class="input-search"
              @focus="handleFocus"
            ></el-input>
            <div class="btn-search" @click="sendFilter">
              <i class="el-icon-search"></i>
            </div>
          </div>
          <el-form-item label="状态">
            <el-select
              v-model="filterList.status"
              style="width: 200px;"
              filterable
              placeholder="请选择"
              @change="sendFilter"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="未核对" value="0"></el-option>
              <el-option label="已核对" value="1"></el-option>
              <el-option label="已对账" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="data">
        <BaseTable
          ref="myTabel"
          :height="450"
          :tableData="tableData"
          :loading="loading"
          :isPager="false"
          :border="true"
        >
        </BaseTable>
        <div class="total">
          <div>总金额：<span class="red">{{ totalAmount }} USD</span></div>
          <div>总条目：<span class="red">{{ total }}</span></div>
          <div>已核对：<span class="red">{{ checked }}</span></div>
          <div>未核对：<span class="red">{{ notChecked }}</span></div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput.vue')
import { getReconDetailPages } from '@/api/fee'
import { throttle } from '@/utils/common'
export default {
  components: {
    BaseTable,
    BaseMutiInput
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      tableData: {
        fileds: [
          {
            filed: 'errorMsgTypeName',
            label: '对账结果',
            width: '200',
            resizable: true
          },
          {
            filed: 'companyName',
            label: '客户',
            width: '150',
            resizable: true
          },
          {
            filed: 'invoiceDate',
            label: '账单日期',
            formatter: (row) => {
              return row.invoiceDate ? /\d{4}-\d{1,2}-\d{1,2}/g.exec(row.invoiceDate)[0] : ''
            },
            width: '130',
            resizable: true
          },
          {
            filed: 'invoiceNumber',
            label: '账单号码',
            width: '150',
            resizable: true
          },
          {
            filed: 'invoiceAmount',
            label: '账单总金额',
            formatter: (row) => {
              return row.invoiceAmount ? row.invoiceAmount + ' USD' : '0 USD'
            },
            width: '140',
            resizable: true
          },
          {
            filed: 'onlineDate',
            label: '制单日期',
            formatter: (row) => {
              return row.onlineDate ? /\d{4}-\d{1,2}-\d{1,2}/g.exec(row.onlineDate)[0] : ''
            },
            width: '130',
            resizable: true
          },
          {
            filed: 'pickUpRecord',
            label: '提货批次号',
            width: '150',
            resizable: true
          },
          {
            filed: 'mstTrackingNumber',
            label: '主单号',
            width: '170',
            resizable: true
          },
          {
            filed: 'referenceNumber1',
            label: '参考单号1',
            width: '170',
            resizable: true
          },
          {
            filed: 'referenceNumber2',
            label: '参考单号2',
            width: '170',
            resizable: true
          },
          {
            filed: 'hawbPacage',
            label: '子单件数',
            width: '120',
            resizable: true
          },
          {
            filed: 'trackingNumber',
            label: '子单号',
            width: '170',
            resizable: true
          },
          {
            filed: 'upsCreateWeight',
            label: '打单重',
            width: '120',
            formatter: (row) => {
              return row.upsCreateWeight ? row.upsCreateWeight + ' LB' : '0 LB'
            },
            resizable: true
          },
          {
            filed: 'upsChargeWeight',
            label: '计费重',
            width: '120',
            formatter: (row) => {
              return row.upsChargeWeight ? row.upsChargeWeight + ' LB' : '0 LB'
            },
            resizable: true
          },
          {
            filed: 'auditedDimension',
            label: '调整尺寸',
            width: '120',
            formatter: (row) => {
              return row.auditedDimension ? row.auditedDimension + ' mm' : '0 mm'
            },
            resizable: true
          },
          {
            filed: 'costType',
            label: '费用大类',
            width: '120',
            resizable: true
          },
          {
            filed: 'feeCode',
            label: '费用代码',
            width: '120',
            resizable: true
          },
          {
            filed: 'feeName',
            label: '费用名称',
            width: '200',
            resizable: true
          },
          {
            filed: 'actualCost',
            label: '实际费用',
            width: '120',
            formatter: (row) => {
              return row.actualCost ? row.actualCost + ' USD' : '0 USD'
            },
            resizable: true
          },
          {
            filed: 'systemCost',
            label: '系统成本',
            width: '120',
            formatter: (row) => {
              return row.systemCost ? row.systemCost + ' USD' : '0 USD'
            },
            resizable: true
          },
          {
            filed: 'fromPostalCode',
            label: '发件邮编',
            width: '120',
            resizable: true
          },
          {
            filed: 'toPostalCode',
            label: '收件邮编',
            width: '120',
            resizable: true
          },
          {
            filed: 'createClass',
            label: '打单值',
            width: '120',
            formatter: (row) => {
              return row.createClass ? row.createClass : '0'
            },
            resizable: true
          },
          {
            filed: 'actualClass',
            label: '实际打单值',
            width: '120',
            formatter: (row) => {
              return row.actualClass ? row.actualClass : '0'
            },
            resizable: true
          },
          {
            filed: 'blNo',
            label: '提单号码',
            width: '170',
            resizable: true
          },
          {
            filed: 'reconDate',
            label: '对账时间',
            formatter: (row) => {
              return row.reconDate ? row.reconDate.slice(0, row.reconDate.length - 3) : ''
            },
            width: '150',
            resizable: true
          },
          {
            filed: 'reconName',
            label: '对账人',
            width: '120',
            resizable: true
          },
          {
            filed: 'mblDate',
            label: '提单登记日期',
            formatter: (row) => {
              return row.mblDate ? /\d{4}-\d{1,2}-\d{1,2}/g.exec(row.mblDate)[0] : ''
            },
            width: '130',
            resizable: true
          }
        ],
        columns: []
      },
      filterList: {
        status: '',
        numbers: ''
      },
      isActive: false,
      loading: false,
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      totalAmount: 0,
      total: 0,
      checked: 0,
      notChecked: 0,
      searchVal: '',
      listStatus: true
    }
  },
  watch: {
    async dialogVisible(val) {
      if (val) {
        this.listStatus = true
        this.getLists(this.pager)
        // 触底更新
        this.$nextTick(() => {
          this.$refs.myTabel.$refs.multipleTable.bodyWrapper.addEventListener('scroll', (res) => {
            const target = res.target
            const clientHeight = target.clientHeight
            const scrollTop = target.scrollTop
            const scrollHeight = target.scrollHeight
            if (clientHeight + scrollTop === scrollHeight && this.listStatus) {
              this.getLists('add')
            }
          }, true)
        })
      }
    }
  },
  methods: {
    getLists: throttle(async function(params) {
      try {
        this.loading = true
        if (params === 'add') {
          this.pager = {
            pageIndex: this.pager.pageIndex + 1,
            pageSize: 20
          }
          params = {
            ...this.pager,
            queryParam: {
              ...this.formatterFilter()
            }
          }
        } else {
          params = {
            ...params,
            queryParam: {
              ...this.formatterFilter()
            }
          }
        }
        const res = await getReconDetailPages(params)
        if (res.success) {
          this.loading = false
          if (res.data.list.length === 0 && this.listStatus) {
            this.listStatus = false
            return
          }
          this.tableData.columns.push(...res.data.list)
          res.data.totalAmount && (this.totalAmount = res.data.totalAmount)
          this.total = res.data.total
          this.checked = res.data.checked
          this.notChecked = res.data.notChecked
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    }, 500),
    cancel() {
      this.totalAmount = 0
      this.total = 0
      this.checked = 0
      this.notChecked = 0
      this.tableData.columns = []
      this.filterList = {
        status: '',
        numbers: ''
      }
      this.searchVal = ''
      this.pager = {
        pageIndex: 1,
        pageSize: 20
      }
      this.$refs.baseMutiInput.reset()
      this.$refs.popover.doClose()
      this.$emit('cancel')
    },
    formatterFilter() {
      return {
        id: this.rowData.id,
        numbers:
          this.filterList.numbers.length > 0
            ? this.filterList.numbers
            : undefined,
        status:
          this.filterList.status !== '' ? this.filterList.status : undefined
      }
    },
    sendFilter(val) {
      this.totalAmount = 0
      this.total = 0
      this.checked = 0
      this.notChecked = 0
      this.listStatus = true
      this.tableData.columns = []
      const { numbers } = this.filterList
      if (val === 'popover' && numbers && numbers !== '') {
        this.searchVal = this.filterList.numbers.join(';')
        this.$refs.popover.doClose()
      }
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = params
      this.getLists(params)
    },
    handleFocus() {
      this.$refs.popover.doShow()
    },
    handleEmpty() {
      this.filterList.numbers = ''
      this.searchVal = ''
      this.$refs.baseMutiInput.reset()
      this.$refs.popover.doClose()
      this.sendFilter()
    },
    closeSearchPanel() {
      this.$refs.popover.doClose()
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  .header {
    display: flex;
    justify-content: flex-end;
  }
  .data {
    width: 1160px;
    height: auto;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    box-sizing: border-box;
    .total {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      background: #F7F7F7;
      border: 1px solid #EEEEEE;
      border-radius: 0px 0px 4px 4px;
      box-sizing: border-box;
      border-top: 0;
      div {
        height: 16px;
        font-size: 12px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        & + div {
          margin-left: 40px;
        }
      }
    }
    .red {
      color: #FE453D;
    }
  }
  .form {
    display: flex;
    .search {
      position: relative;
      display: flex;
      .searchPop {
        /deep/ .el-popover {
          z-index: 9999;
          right: 0;
        }
        .close {
          position: absolute;
          top: 6px;
          right: 10px;
          cursor: pointer;
          font-size: 20px;
        }
      }
      .input-search {
        width: 240px;
      }
      .btn-search {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
        box-sizing: border-box;
        border-left: 1px solid #EEEEEE;
      }
    }
    .popover_footer {
      margin-top: 20px;
      & div {
        border-radius: 4px 4px 4px 4px;
        cursor: pointer;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        width: 80px;
        height: 30px;
      }
      .clear {
        background: #EEEEEE;
        color: #999999;
        margin-right: 10px;
      }
      .confirm {
        background: #2395F3;
        color: #FFFFFF;
      }
    }
  }

}
</style>
