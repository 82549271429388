<template>
  <el-dialog
    v-drag
    title="导出"
    :visible.sync="dialogVisible"
    width="506px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="16%"
  >
    <div class="content">
      <div class="flex_c_c" style="margin-bottom: 20px;" @click="exportOriginalList">原始列表</div>
      <div class="flex_c_c" @click="exportTemplate1">账单模板一</div>
      <!-- <div class="flex_c_c">账单模板二</div> -->
    </div>
  </el-dialog>
</template>

<script>
import { derive } from '@/utils/common'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    // 导出
    exportOriginalList() {
      const params = {
        invoiceNumber: this.rowData.invoiceNumber
      }
      derive('ReconInfomation/exportOriginalList', 'get', params)
    },
    // 导出
    exportTemplate1() {
      const params = {
        invoiceNumber: this.rowData.invoiceNumber
      }
      derive('ReconInfomation/exportTemplate1', 'get', params)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% - 86px);
  margin: 0 auto;
  & div {
    width: 180px;
    height: 40px;
    background: #2395F3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
  }
}
</style>
