<template>
  <el-dialog
    v-drag
    title="导入"
    :visible.sync="dialogVisible"
    width="520px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="beforeClose"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <el-row style="width: 100%">
          <el-col :span="12">
            <div class="form-item">
              <label class="lable"><span class="red">* </span>账单类型</label>
              <el-form-item prop="type">
                <el-select v-model="form.type" style="width: 190px;" placeholder="请选择账单类型">
                  <el-option label="UPS" :value="0"></el-option>
                  <el-option label="FedEx" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="form-item">
              <label class="lable"><span class="red">* </span>币制</label>
              <el-form-item prop="currency">
                <el-select v-model="form.currency" style="width: 190px;" placeholder="请选择币制">
                  <el-option label="USD" value="USD"></el-option>
                  <el-option label="RMB" value="RMB"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class="upload">
        <el-upload
          class="upload-demo"
          drag
          action="#"
          list-type="picture"
          :http-request="httpRequest"
          :show-file-list="false"
        >
          <svg-icon iconClass="upload" className="icon" />
          <p class="p1">拖拽你的文件到此处</p>
          <p class="p2">或</p>
          <div class="btn">点击上传</div>
        </el-upload>
      </div>
      <div v-show="fileList[0]" class="fileLists">
        <div :style="{'visibility':fileList[0] && fileList[0].fileName ? 'visible' : 'hidden'}" class="item-upload-area">
          <div style="display: flex">
            <img src="@/assets/images/const/xlsx.png" />
            <div class="des">
              <p class="fileName">{{ fileList[0] && fileList[0].fileName }}</p>
              <p class="fileSize">{{ fileList[0] && fileList[0].fileSize }} KB</p>
            </div>
          </div>
          <i class="el-icon-circle-check"></i>
        </div>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel" @click="cancel('form')">取 消</div>
      <div class="ok" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import * as XLSX from 'xlsx'
import { ReconInfoImport, existsReconInvoiceNo, deleteReconInvoce } from '@/api/fee'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        type: '',
        currency: ''
      },
      rules: {
        type: [{ required: true, message: '请选择账单类型', trigger: 'change' }],
        currency: [{ required: true, message: '请选择币制', trigger: 'change' }]
      },
      fileList: [],
      excelData: [],
      state: '', // 记录状态
      resultData: '' // 记录返回的数据
    }
  },
  methods: {
    beforeClose() {
      this.cancel('form')
    },
    cancel(formName) {
      this.reset(formName)
      this.$emit('cancel')
    },
    reset(formName) {
      this.$refs[formName].resetFields()
      this.fileList = []
      this.excelData = []
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.fileList.length === 0) {
            this.$message.error('请上传xlsx文件')
            return
          }
          if (this.state === 'success') {
            this.add(formName)
            return
          }
          if (this.state === 'warning') {
            this.$confirm('该账单号码已经存在对账记录，是否删除原对账记录重新对账？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              iconClass: 'warning',
              showClose: false,
              closeOnPressEscape: false,
              closeOnClickModal: false,
              center: true
            }).then(() => {
              try {
                deleteReconInvoce({ id: this.resultData }).then(res => {
                  if (!res.success) {
                    this.$message.error(res.errorMessage)
                  } else {
                    this.add(formName)
                  }
                })
              } catch (error) {
                console.error(error)
              }
            })
            return
          }
          if (this.state === 'error') {
            this.$message.error(this.resultData)
          }
        } else {
          this.$message.error('请填写完整')
          return false
        }
      })
    },
    async add(formName) {
      const params = {
        ...this.form,
        excelData: this.excelData
      }
      try {
        const result = await ReconInfoImport(params)
        if (result.success) {
          this.$message.success('导入成功')
          this.$emit('ok')
          this.reset(formName)
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    httpRequest({ file }) {
      this.fileList = [{
        fileName: file.name,
        fileSize: Math.ceil(file.size / 1024)
      }]
      const reader = new FileReader()
        const that = this
        reader.onload = async function(e) {
          const data = e.target.result
          const wb = XLSX.read(data, { type: 'binary' })
          const jdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
          const newData = jdata
            .map((a) => {
              const newKey = Object.keys(a).sort()
              const newObj = {}
              newKey.forEach((b, i) => {
                newObj[newKey[i].toLowerCase()] = a[newKey[i]]
              })
              return newObj
            })
            .map((a) => {
              return {
                'inV_DATE': that.formatDate(a.inv_date, '/'),
                'inV_NO': a.inv_no,
                'inV_VALUE': a.inv_value,
                'upS_CREATE_DATE': that.formatDate(a.ups_create_date, '/'),
                'picK_UP_RECORD': a.pick_up_record ? a.pick_up_record : undefined,
                'upS_MAWB_NO': a.ups_mawb_no,
                'upS_REFERNCE_1': a.ups_reference_1,
                'upS_REFERNCE_2': a.ups_reference_2,
                'hawB_PACKAGE': a.hawb_package,
                'upS_HAWB_NO': a.ups_hawb_no,
                'upS_CREATE_WEIGHT': a.ups_create_weight,
                'upS_CHARGE_WEIGHT': a.ups_charge_weight,
                'auditeD_DIMENSIONS': a.audited_dimensions ? a.audited_dimensions : undefined,
                'cosT_TYPE': a.cost,
                'feE_CODE': a.fee_code,
                'feE_NAME': a.fee_name,
                'actuaL_COST': a.actual_cost,
                'shippinG_CODE': a.shipping_code,
                'incominG_CODE': a.incoming_code,
                'creatE_CLASS': a.create_class,
                'actuaL_CLASS': a.actual_class,
                'upS_ACCOUNT': a.ups_acount_num
              }
            })
          try {
            const result = await existsReconInvoiceNo({ invoiceNo: newData[0].inV_NO })
            result.data === null && result.success && (that.state = 'success')
            result.data && result.success && (that.state = 'warning', that.resultData = result.data)
            !result.success && (that.state = 'error', that.resultData = result.errorMessage)
          } catch (error) {
            console.error(error)
          }
          that.excelData = newData
        }
        reader.readAsBinaryString(file)
    },
    formatDate(numb, format) {
      const time = new Date((numb - 1) * 24 * 3600000 + 1)
      time.setYear(time.getFullYear() - 70)
      const year = time.getFullYear() + ''
      const month = time.getMonth() + 1 + ''
      const date = time.getDate() + ''
      if (format && format.length === 1) {
        return year + format + month + format + date
      }
      return year + (month < 10 ? '0' + month : month) + (date < 10 ? '0' + date : date)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  margin: 0 auto;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    .el-col:nth-child(2n) {
      display: flex;
      justify-content: flex-end;
    }
  }
  .form-item {
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
  }
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    margin-right: 20px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.upload {
  width: 400px;
  height: 200px;
  background: #F7F7F7;
  border-radius: 6px 6px 6px 6px;
  border: 0;
  margin-top: 20px;
  .icon {
    font-size: 40px;
    margin: 26px auto 0;
    color: #2395F3;
  }
  .p1, .p2 {
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    text-align: center;
    margin-top: 10px;
  }
  .btn {
    width: 120px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #2395f3;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 40px;
    text-align: center;
    margin: 10px auto 0;
    box-sizing: border-box;
  }
  /deep/ .upload-demo {
    width: 100%;
    height: 100%;
  }
  /deep/.el-upload {
    width: 100%;
    height: 100%;
  }
  /deep/.el-upload-dragger {
    width: 100%;
    height: 100%;
  }
  /deep/.el-upload-dragger {
    background: #F7F7F7;;
    border: 0;
  }
}
.fileLists {
    width: 400px;
    height: 64px;
    padding: 12px 24px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px 6px 6px 6px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .icon {
      color: #b8c3de;
      line-height: 40px;
      cursor: pointer;
    }
    .file {
      display: flex;
    }
  }
.des {
  margin-left: 16px;
}
.fileName {
  width: auto;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #25396f;
  line-height: 19px;
}
.fileSize {
  width: auto;
  margin-top: 5px;
  height: 16px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #7c8db5;
  line-height: 16px;
}
.item-upload-area {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    font-size: 18px;
      color: #B8C3DE;
      cursor: pointer;
  }
}
.el-icon-circle-check {
  color: #35BA83 !important;
}
</style>
