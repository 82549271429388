var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "核对",
        visible: _vm.dialogVisible,
        width: "1200px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "header" }, [
          _c(
            "div",
            [
              _c("label", { staticStyle: { "margin-right": "15px" } }, [
                _vm._v("选择客户"),
              ]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "400px" },
                  model: {
                    value: _vm.companyId,
                    callback: function ($$v) {
                      _vm.companyId = $$v
                    },
                    expression: "companyId",
                  },
                },
                _vm._l(_vm.companyOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "data" },
          [
            _c("BaseTable", {
              ref: "myTabel",
              attrs: {
                height: 448,
                tableData: _vm.tableData,
                loading: _vm.loading,
                isPager: false,
                border: true,
              },
              on: { handleSelectionChange: _vm.handleSelectionChange },
            }),
            _c("div", { staticClass: "select" }, [
              _vm._v("已选择 "),
              _c("span", [_vm._v(_vm._s(_vm.selectArr.length))]),
              _vm._v(" 项"),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c("div", { staticClass: "ok flex_c_c", on: { click: _vm.ok } }, [
            _vm._v("确 定"),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }