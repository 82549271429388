<template>
  <el-dialog
    v-drag
    title="费用中英文对照表"
    :visible.sync="dialogVisible"
    width="1160px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <ul class="row">
        <li class="row header">
          <div class="cell1">对账类型</div>
          <div class="cell2">费用大类</div>
          <div class="cell3">英文名称</div>
          <div class="cell4">中文名称</div>
          <div class="cell5">创建人</div>
          <div class="cell6">创建时间</div>
          <div class="cell7">是否模糊匹配</div>
          <div class="cell8">操作</div>
        </li>
        <div class="data">
          <li v-for="(item,$index) in dataList" :key="$index" class="row bodyRow">
            <div class="cell1">
              <span v-if="!item.isEdit">{{ item.type ? 'FedEx' : 'UPS' }}</span>
              <el-select v-else v-model="item.type" placeholder="请选择">
                <el-option label="UPS" :value="0"></el-option>
                <el-option label="FedEx" :value="1"></el-option>
              </el-select>
            </div>
            <div class="cell2">
              <span v-if="!item.isEdit">{{ item.category }}</span>
              <el-input v-else v-model="item.category" placeholder="请输入"></el-input>
            </div>
            <div class="cell3">
              <el-tooltip
                v-if="!item.isEdit"
                ref="tlp1"
                :content="item.nameEN"
                effect="dark"
                :disabled="!item.tooltipFlag"
                :enterable="false"
                placement="top"
              >
                <div class="tlp" @mouseenter="visibilityChange($event, 'tlp1', item)">{{ item.nameEN }}</div>
              </el-tooltip>
              <el-input v-else v-model="item.nameEN" oninput="value=value.replace(/[^\w\s]/g,'')" placeholder="请输入"></el-input>
            </div>
            <div class="cell4">
              <el-tooltip
                v-if="!item.isEdit"
                ref="tlp2"
                :content="item.nameCH"
                effect="dark"
                :disabled="!item.tooltipFlag"
                :enterable="false"
                placement="top"
              >
                <div class="tlp" @mouseenter="visibilityChange($event, 'tlp2', item)">{{ item.nameCH }}</div>
              </el-tooltip>
              <el-input v-else v-model="item.nameCH" placeholder="请输入"></el-input>
            </div>
            <div class="cell5">
              <el-tooltip
                v-if="!item.isEdit"
                ref="tlp3"
                :content="item.createdName"
                effect="dark"
                :disabled="!item.tooltipFlag"
                :enterable="false"
                placement="top"
              >
                <div class="tlp" @mouseenter="visibilityChange($event, 'tlp3', item)">{{ item.createdName }}</div>
              </el-tooltip>
              <el-input v-else v-model="item.createdName" placeholder="不可填" disabled></el-input>
            </div>
            <div class="cell6">
              <span v-if="!item.isEdit">{{ item.createdOn | dateFilter }}</span>
              <el-input v-else v-model="item.createdOn" placeholder="不可填" disabled></el-input>
            </div>
            <div class="cell7">
              <span v-if="!item.isEdit">{{ item.isLike ? '是' : '否' }}</span>
              <el-select v-else v-model="item.isLike" placeholder="请选择">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </div>
            <div class="cell8">
              <template v-if="!item.isEdit">
                <img src="@/assets/images/const/edit.png" title="编辑" @click="editRow(item)">
                <i class="icon el-icon-delete" title="删除" @click="deleteRow(item.id)"></i>
              </template>
              <span v-else class="add" @click="update($index, item)">确定</span>
            </div>
          </li>
          <li class="row bodyRow">
            <div class="cell1">
              <el-select v-model="contrastObj.type" placeholder="请选择">
                <el-option label="UPS" :value="0"></el-option>
                <el-option label="FedEx" :value="1"></el-option>
              </el-select>
            </div>
            <div class="cell2">
              <el-input v-model="contrastObj.category" placeholder="请输入"></el-input>
            </div>
            <div class="cell3">
              <el-input v-model="contrastObj.nameEN" placeholder="请输入"></el-input>
            </div>
            <div class="cell4">
              <el-input v-model="contrastObj.nameCH" placeholder="请输入"></el-input>
            </div>
            <div class="cell5">
              <el-input placeholder="不可填" disabled></el-input>
            </div>
            <div class="cell6">
              <el-input placeholder="不可填" disabled></el-input>
            </div>
            <div class="cell7">
              <el-select v-model="contrastObj.isLike" placeholder="请选择">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </div>
            <div class="cell8">
              <span class="add" @click="add">添加</span>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </el-dialog>
</template>
<script>
import { addReconFeeNameMapping, getReconFeeNameMappingList, deleteReconFeeNameMapping, updateReconFeeNameMapping } from '@/api/fee'
export default {
  filters: {
    dateFilter(date) {
      if (date) {
        return date.slice(0, date.length - 3)
      } else {
        return ''
      }
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataList: [],
      contrastObj: {
        type: '',
        category: '',
        nameCH: '',
        nameEN: '',
        isLike: ''
      }
    }
  },
  watch: {
    async dialogVisible(val) {
      if (val) {
        this.getList()
      }
    }
  },
  methods: {
    async getList() {
      try {
        const result = await getReconFeeNameMappingList()
        if (result.success) {
          this.dataList = result.data.map(a => {
            return {
              ...a,
              isEdit: false,
              tooltipFlag: false
            }
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    async add() {
      const { type, category, nameEN, nameCH, isLike } = this.contrastObj
      if (type === '' || category === '' || nameEN === '' || nameCH === '' || isLike === '') {
        this.$message.error('请填写完整')
        return
      }
      try {
        const result = await addReconFeeNameMapping(this.contrastObj)
        if (result.success) {
          this.getList()
          this.contrastObj = {
            type: '',
            category: '',
            nameCH: '',
            nameEN: '',
            isLike: ''
          }
          this.$message.success(result.errorMessage)
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async deleteRow(id) {
      try {
        const result = await deleteReconFeeNameMapping({ id })
        if (result.success) {
          this.getList()
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async editRow(item) {
      item.isEdit = true
    },
    async update(index, item) {
      const { type, category, nameEN, nameCH, isLike } = item
      if (type === '' || category === '' || nameEN === '' || nameCH === '' || isLike === '') {
        this.$message.error('请填写完整')
        return
      }
      try {
        const result = await updateReconFeeNameMapping(item)
        if (result.success) {
          console.log(result)
          this.dataList.splice(index, 1, { ...item, isEdit: false })
          this.$message.success(result.errorMessage)
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      this.contrastObj = {
        type: '',
        category: '',
        nameCH: '',
        nameEN: '',
        isLike: ''
      }
      this.$emit('cancel')
    },
    visibilityChange(event, ref, item) {
      console.log(event)
      const ev = event.target
      const evWeight = ev.scrollWidth // 文本的实际宽度
      const contentWeight = this.$refs[ref][0].$el.parentNode.clientWidth // 文本容器宽度(父节点)
      if (evWeight > contentWeight) {
        // 文本宽度 > 实际内容宽度  -->内容溢出,则显示悬浮
        item.tooltipFlag = true
      } else {
        // 否则为未溢出，不显示悬浮
        item.tooltipFlag = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: calc(100% - 40px);
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  margin: 0 auto;
  overflow: hidden;
  .data {
    width: 100%;
    min-height: 400px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    .tlp {
      width: 100%;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 10px;
      box-sizing: border-box;
    }
  }
  .row {
      width: 100%;
      height: auto;
  }
  .bodyRow {
      width: 1080px;
      height: 40px;
      opacity: 1;
      border-bottom: 1px solid #EEEEEE;
      display: flex;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 40px;
      text-align: center;
      &:last-child {
          border-bottom: 1px solid #EEEEEE;
      }
  }
  .header {
      width: 1080px;
      height: 40px;
      background: #F7F7F7;
      opacity: 1;
      border-bottom: 1px solid #EEEEEE;
      display: flex;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
      text-align: center;
  }
  .cell1, .cell2, .cell8 {
      width: 120px;
      border-right: 1px solid #EEEEEE;
  }
  .cell3 {
      width: 200px;
      border-right: 1px solid #EEEEEE;
  }
  .cell4, .cell6 {
      width: 160px;
      border-right: 1px solid #EEEEEE;
  }
  .cell5, .cell7 {
      width: 100px;
      border-right: 1px solid #EEEEEE;
  }
  .cell8 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 0;
    & img {
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .add {
      color: #2395F3;
      cursor: pointer;
  }
  .delete {
      background: #ffffff;
  }
  .icon {
    color: #2395F3;
    font-size: 16px;
    cursor: pointer;
  }
  :deep(.el-input__inner) {
      border: 0;
  }
}
</style>
