<template>
  <el-dialog
    v-drag
    title="核对"
    :visible.sync="dialogVisible"
    width="1200px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="header">
        <div>
          <label style="margin-right: 15px;">选择客户</label>
          <el-select v-model="companyId" style="width: 400px;">
            <el-option
              v-for="item in companyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="data">
        <BaseTable
          ref="myTabel"
          :height="448"
          :tableData="tableData"
          :loading="loading"
          :isPager="false"
          :border="true"
          @handleSelectionChange="handleSelectionChange"
        >
        </BaseTable>
        <div class="select">已选择 <span>{{ selectArr.length }}</span> 项</div>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
const BaseTable = () => import('@/components/table/BaseTable')
import { getReconDetailPages, ReconDetailReview } from '@/api/fee'
import { throttle } from '@/utils/common'
export default {
  components: {
    BaseTable
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => { return {} }
    },
    companyOptions: {
      type: Array,
      default: () => { return [] }
    }
  },
  data() {
    return {
      tableData: {
        fileds: [
          {
            filed: 'errorMsgTypeName',
            label: '原因',
            width: '200',
            resizable: true
          },
          {
            filed: 'mstTrackingNumber',
            label: '主单号',
            width: '170',
            resizable: true
          },
          {
            filed: 'referenceNumber1',
            label: '参考单号1',
            width: '170',
            resizable: true
          },
          {
            filed: 'referenceNumber2',
            label: '参考单号2',
            width: '170',
            resizable: true
          },
          {
            filed: 'hawbPacage',
            label: '子单件数',
            width: '120',
            resizable: true
          },
          {
            filed: 'upsCreateWeight',
            label: '打单重',
            width: '120',
            formatter: (row) => {
              if (row.upsCreateWeight) {
                return row.upsCreateWeight + ' KG'
              } else {
                return '0 KG'
              }
            },
            resizable: true
          },
          {
            filed: 'upsChargeWeight',
            label: '计费重',
            width: '120',
            formatter: (row) => {
              if (row.upsChargeWeight) {
                return row.upsChargeWeight + ' KG'
              } else {
                return '0 KG'
              }
            },
            resizable: true
          },
          {
            filed: 'auditedDimension',
            label: '调整尺寸',
            width: '120',
            formatter: (row) => {
              if (row.auditedDimension) {
                return row.auditedDimension + ' mm'
              } else {
                return '0 mm'
              }
            },
            resizable: true
          },
          {
            filed: 'costType',
            label: '费用大类',
            width: '120',
            resizable: true
          },
          {
            filed: 'feeCode',
            label: '费用代码',
            width: '120',
            resizable: true
          },
          {
            filed: 'feeName',
            label: '费用名称',
            width: '200',
            resizable: true
          },
          {
            filed: 'actualCost',
            label: '实际费用',
            width: '120',
            formatter: (row) => {
              if (row.actualCost) {
                return row.actualCost + ' USD'
              } else {
                return '0 USD'
              }
            },
            resizable: true
          },
          {
            filed: 'systemCost',
            label: '系统成本',
            width: '120',
            formatter: (row) => {
              if (row.systemCost) {
                return row.systemCost + ' USD'
              } else {
                return '0 USD'
              }
            },
            resizable: true
          },
          {
            filed: 'createClass',
            label: '打单值',
            width: '120',
            formatter: (row) => {
              if (row.createClass) {
                return row.createClass + ' USD'
              } else {
                return '0 USD'
              }
            },
            resizable: true
          },
          {
            filed: 'actualClass',
            label: '实际打单值',
            width: '120',
            formatter: (row) => {
              if (row.actualClass) {
                return row.actualClass + ' USD'
              } else {
                return '0 USD'
              }
            },
            resizable: true
          },
          {
            filed: 'accountNumber',
            label: '制单账号',
            width: '120',
            resizable: true
          }
        ],
        columns: [],
        mutiCheck: true,
        mutiFixed: 'left'
      },
      loading: false,
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      selectArr: [],
      companyId: '',
      listStatus: true
    }
  },
  watch: {
    async dialogVisible(val) {
      if (val) {
        this.listStatus = true
        this.getLists(this.pager)
        // 触底更新
        this.$nextTick(() => {
          this.$refs.myTabel.$refs.multipleTable.bodyWrapper.addEventListener('scroll', (res) => {
            const { clientHeight, scrollTop, scrollHeight } = res.target
            if (clientHeight + scrollTop === scrollHeight && this.listStatus) {
              this.getLists('add')
            }
          }, true)
        })
      }
    }
  },
  methods: {
    getLists: throttle(async function(params) {
      try {
        this.loading = true
        if (params === 'add') {
          this.pager = {
            pageIndex: this.pager.pageIndex + 1,
            pageSize: 20
          }
          params = {
            ...this.pager,
            queryParam: {
              id: this.rowData.id
            }
          }
        } else {
          params = {
            ...params,
            queryParam: {
              id: this.rowData.id
            }
          }
        }
        const res = await getReconDetailPages(params)
        if (res.success) {
          this.loading = false
          if (res.data.list.length === 0 && this.listStatus) {
            this.listStatus = false
            return
          }
          this.tableData.columns.push(...res.data.list)
          res.data.totalAmount && (this.totalAmount = res.data.totalAmount)
          this.total = res.data.total
          this.checked = res.data.checked
          this.notChecked = res.data.notChecked
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    }, 500),
    handleSelectionChange(val) {
      this.selectArr = val
    },
    cancel() {
      this.tableData.columns = []
      this.pager = {
        pageIndex: 1,
        pageSize: 20
      }
      this.companyId = ''
      this.$emit('cancel')
    },
    async ok() {
      if (!this.companyId) {
        this.$message.error('请选择客户')
        return
      }
      if (this.selectArr.length === 0) {
        this.$message.error('请勾选核对项')
        return
      }
      try {
        const params = {
          companyId: this.companyId,
          invoiceId: this.rowData.id,
          ids: this.selectArr.map(a => a.id)
        }
        const result = await ReconDetailReview(params)
        if (result.success) {
          this.$message.success(result.errorMessage)
          this.selectArr = []
          this.companyId = ''
          this.$emit('ok')
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  .header {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .data {
    width: 1160px;
    height: auto;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    box-sizing: border-box;
    overflow: hidden;
    .fTable {
      height: 448px;
    }
    .select {
      margin-top: 20px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      span {
        color: #2395F3;
      }
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
