var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "账单明细",
        visible: _vm.dialogVisible,
        width: "1200px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                staticClass: "form",
                attrs: { model: _vm.filterList, "label-width": "58px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "search" },
                  [
                    _c(
                      "el-popover",
                      {
                        ref: "popover",
                        staticClass: "searchPop",
                        attrs: {
                          placement: "top",
                          title: "请输入主单号/参考单号搜索",
                          trigger: "click",
                          width: "300",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "close",
                            on: { click: _vm.closeSearchPanel },
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        ),
                        _c("base-muti-input", {
                          ref: "baseMutiInput",
                          model: {
                            value: _vm.filterList.numbers,
                            callback: function ($$v) {
                              _vm.$set(_vm.filterList, "numbers", $$v)
                            },
                            expression: "filterList.numbers",
                          },
                        }),
                        _c("div", { staticClass: "popover_footer flex_c_c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "clear flex_c_c",
                              on: { click: _vm.handleEmpty },
                            },
                            [_vm._v("清空")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "confirm flex_c_c",
                              on: {
                                click: function ($event) {
                                  return _vm.sendFilter("popover")
                                },
                              },
                            },
                            [_vm._v("确定")]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("el-input", {
                      staticClass: "input-search",
                      attrs: {
                        value: _vm.searchVal,
                        placeholder: "请输入提单号搜索",
                      },
                      on: { focus: _vm.handleFocus },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "btn-search",
                        on: { click: _vm.sendFilter },
                      },
                      [_c("i", { staticClass: "el-icon-search" })]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "200px" },
                        attrs: { filterable: "", placeholder: "请选择" },
                        on: { change: _vm.sendFilter },
                        model: {
                          value: _vm.filterList.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterList, "status", $$v)
                          },
                          expression: "filterList.status",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "全部", value: "" },
                        }),
                        _c("el-option", {
                          attrs: { label: "未核对", value: "0" },
                        }),
                        _c("el-option", {
                          attrs: { label: "已核对", value: "1" },
                        }),
                        _c("el-option", {
                          attrs: { label: "已对账", value: "2" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "data" },
          [
            _c("BaseTable", {
              ref: "myTabel",
              attrs: {
                height: 450,
                tableData: _vm.tableData,
                loading: _vm.loading,
                isPager: false,
                border: true,
              },
            }),
            _c("div", { staticClass: "total" }, [
              _c("div", [
                _vm._v("总金额："),
                _c("span", { staticClass: "red" }, [
                  _vm._v(_vm._s(_vm.totalAmount) + " USD"),
                ]),
              ]),
              _c("div", [
                _vm._v("总条目："),
                _c("span", { staticClass: "red" }, [_vm._v(_vm._s(_vm.total))]),
              ]),
              _c("div", [
                _vm._v("已核对："),
                _c("span", { staticClass: "red" }, [
                  _vm._v(_vm._s(_vm.checked)),
                ]),
              ]),
              _c("div", [
                _vm._v("未核对："),
                _c("span", { staticClass: "red" }, [
                  _vm._v(_vm._s(_vm.notChecked)),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }