<template>
  <el-dialog
    v-drag
    title="对账"
    :visible.sync="dialogFormVisible"
    width="1200px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    :before-close="cancel"
  >
    <div class="content">
      <BaseTable
        :height="440"
        :tableData="tableData"
        :isPager="false"
        :loading="loading"
        element-loading-text="系统处理中，请稍等..."
        @sortChange="sortChange"
      >
      </BaseTable>
      <div class="pager">
        <span>总系统费用：<span style="color: #FE453D;">{{ systemCostSum }}</span></span>
        <span style="margin-left: 40px">总历史调整费用：<span style="color: #FE453D;">{{ historyFeeSum }}</span></span>
        <span style="margin-left: 40px">总本次调整费用：<span style="color: #2395F3;">{{ thisFeeSum }}</span></span>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
import { getReconciliationResult, reconciliation } from '@/api/fee'
const BaseTable = () => import('@/components/table/BaseTable')

export default {
    components: { BaseTable },
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      tableData: {
        fileds: [
          {
            filed: 'companyName',
            label: '客户',
            width: ''
          },
          {
            filed: 'invoiceDate',
            label: '制单日期',
            width: '',
            formatter: row => {
                return row.invoiceDate.slice(0, 16)
            },
            isSort: true
          },
          {
            filed: 'mstTrackingNumber',
            label: '主单号',
            width: ''
          },
          {
            filed: 'referenceNumber1',
            label: '参考单号',
            width: ''
          },
          {
            filed: 'hawbPacage',
            label: '子单件数',
            width: ''
          },
          {
            filed: 'systemCost',
            label: '系统费用',
            width: '',
            isSort: true,
            formatter: row => {
                return `${row.systemCost} ${row.invoiceCurrency}`
            }
          },
          {
            filed: 'historyFee',
            label: '历史调整费用',
            width: '',
            isSort: true,
            formatter: row => {
                return `${row.historyFee} ${row.companyCurrency}`
            }
          },
          {
            filed: 'thisFee',
            label: '本次调整费用',
            width: '',
            isSort: true,
            formatter: row => {
                return `${row.historyFee} ${row.companyCurrency}`
            }
          }
        ],
        columns: [],
        total: 0
      },
      sortObj: {
        filed: 'invoiceDate',
        order: 'desc'
      },
      loading: false
    }
  },
  computed: {
    systemCostSum() {
      let sum = 0
      this.tableData.columns.forEach(a => {
        sum += a.systemCost
      })
      return `${sum} ${this.tableData.columns[0]?.invoiceCurrency}`
    },
    historyFeeSum() {
      let sum = 0
      this.tableData.columns.forEach(a => {
        sum += a.historyFee
      })
      return `${sum} ${this.tableData.columns[0]?.companyCurrency}`
    },
    thisFeeSum() {
      let sum = 0
      this.tableData.columns.forEach(a => {
        sum += a.thisFee
      })
      return `${sum} ${this.tableData.columns[0]?.companyCurrency}`
    }
  },
  watch: {
    dialogFormVisible(val) {
      if (val) {
        this.getList()
      }
    },
    sortObj() {
      const arr = this.tableData.columns.sort((a, b) => {
        if (this.sortObj.filed === 'invoiceDate') {
          return this.sortObj.order === 'desc' ? a.invoiceDate - b.invoiceDate : b.invoiceDate - a.invoiceDate
        } else if (this.sortObj.filed === 'systemCost') {
          return this.sortObj.order === 'desc' ? a.systemCost - b.systemCost : b.systemCost - a.systemCost
        } else if (this.sortObj.filed === 'historyFee') {
          return this.sortObj.order === 'desc' ? a.historyFee - b.historyFee : b.historyFee - a.historyFee
        } else {
          return this.sortObj.order === 'desc' ? a.thisFee - b.thisFee : b.thisFee - a.thisFee
        }
      })
      this.tableData.columns = arr
    }
  },
  created() {
    // this.getList()
  },
  methods: {
    async ok() {
      this.loading = true
      try {
        const res = await reconciliation({
          id: this.rowData.id
        })
        if (res.success) {
          this.$emit('ok')
          this.$message.success('操作成功')
        } else {
          this.$message.error(res.errorMessage)
        }
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    async getList() {
      try {
        const res = await getReconciliationResult({
          id: this.rowData.id
        })
        if (res.success) {
          this.tableData.columns = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    sortChange(sortObj) {
      this.sortObj = sortObj
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  .pager {
    width: 1160px;
    height: 40px;
    background: #F7F7F7;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
    border: 1px solid #EEEEEE;
    font-size: 12px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 40px;
    text-align: center;
  }
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/.el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>
